import React from "react";
import NextImage from "next/image";
import { ImageProps } from "next/image";
import { ImageLoader } from "next/image";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import { OnLoadingComplete } from "next/dist/shared/lib/get-img-props";
import { PlaceholderValue } from "next/dist/shared/lib/get-img-props";
import imageDefault from "public/assets/images/imageDefault.png";
import { cn } from "@/utils/utils";

export type NextImageProps = Omit<
	React.DetailedHTMLProps<
		React.ImgHTMLAttributes<HTMLImageElement>,
		HTMLImageElement
	>,
	"height" | "width" | "loading" | "ref" | "alt" | "src" | "srcSet" | "class"
> & {
	src: string | StaticImport;
	alt: string;
	width?: number | `${number}`;
	height?: number | `${number}`;
	fill?: boolean;
	loader?: ImageLoader;
	quality?: number | `${number}`;
	priority?: boolean;
	loading?: "eager" | "lazy";
	placeholder?: PlaceholderValue;
	blurDataURL?: string;
	unoptimized?: boolean;
	onLoadingComplete?: OnLoadingComplete;
	layout?: "fixed" | "intrinsic" | "responsive" | "fill";
	objectFit?: string;
	objectPosition?: string;
	lazyBoundary?: string;
	lazyRoot?: string;
	className?: string;
} & React.RefAttributes<HTMLImageElement | null>;
export default function ImageV2(props: NextImageProps) {
	const src = props.src;
	const defaultImage = imageDefault;
	const srcImage =
		typeof src === "string"
			? src.length > 0
				? src.includes("https") ||
				  src.includes("http") ||
				  src.includes("/assets")
					? src
					: process.env.REACT_APP_ASSET_URL + "/uploads/filecloud/" + src
				: defaultImage
			: src;

	if (typeof src === "string" && src.length <= 0) {
		return (
			// <div className="bg-gray-200 place-content-center relative">
			<NextImage
				src={defaultImage}
				alt="default image"
				className={cn(
					"object-cover max-w-[414px] max-h-[414px] mx-auto",
					props.className
				)}
				sizes={props.sizes}
				layout={props.layout}
				width={props.width}
				height={props.height}></NextImage>
			// </div>
		);
	}
	return <NextImage {...props} src={srcImage} />;
}
